<template>
  <b-card
    v-if="data"
    class="card-tiny-line-stats dash-total-card"
    body-class="p-250 p-2"
  >
    <!-- <h6>Total No. Of Coupons</h6> -->
    <div class="card-title">Total No. Of Coupons</div>
    <b-row>
      <b-col class="dash-card-score">
        <h1 class="font-weight-bolder mb-0 card-value">
          1500
        </h1>
        <div class="card-bottom" style="color: #0AC947;">Increased +3</div>
      </b-col>
      <b-col class="dash-card-chart">
        <!-- <vue-apex-charts
          height="70"
          :options="statisticsProfit.chartOptions"
          :series="data.series"
        /> -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {
            type: 'area',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: false,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
}
</script>
<style>
  p.thicker {
  font-weight: 900;
}
</style>
