<template>
  <div>
    <div class="mb-1 font-weight-bolder dash-coupon-list-title h2" id="coupanTitle">
      Coupons Per Category
    </div>
    <div class="coupon-card-container" style="font-size: 17px">
    <b-card v-if="isLoading" style="height: 150px;"> 
        <div class="loading">
          <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
      </b-card>
    <b-card v-else no-body class="card-revenue-budget dash-total-card dash-pie-container h-100">
      <b-row class="mx-0 pl-2 pr-2">
        <b-col md="5">

          <div class="pt-25" @click="handleClickVs">

            <div class="center pt-2 pb-2">
              <v-select  v-model="categories"  @input="updateChartData" 
                  multiple class="couponCustom" shadow 
                  :close-on-select="false" placeholder="Select" label="name"  :options="data"  />
            </div>

            <b-row class="catgory-labels" style="overflow-y:auto;height: 6cm;">
              <b-col md="6" class="p-0 mb-1" v-for="(item, index) in listData" :key="index" :class="index === Object.keys(listData).length - 1
                  ? ''
                  : 'mb-1'
                ">
                <div class="series-info d-flex">
                  <v-icon class="test" :style="'color:' + item.iconColor">mdi-circle-medium</v-icon>
                  <div class="text-muted font-weight-bolder" style="font-size: 13px;">{{ item.text }}</div>
                </div>
                <span class="font-weight-bolder text-black" style="margin-left: 25px">{{ item.result }}</span>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col v-if="show && listData.length > 0" md="5">
          <apexchart type="pie" width="400" :options="chartOptions" :series="chartData" />
        </b-col>
        <b-col md="5" v-else class="d-flex justify-content-center align-items-center h3 text-muted">
          No Data Found
        </b-col>
        <b-col md="2">
          <div class="float-right">
            <vs-button border  @click="reset" class="mt-2 pie-graph-reset-btn">
              Reset
            </vs-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
  </div>
</template>
<style lang="scss">
@import "~@core/scss/base/custom/components/custom-vs-select.scss";
</style>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from 'vue-select';
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    vSelect,
    apexchart: VueApexCharts,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => { },
    },
    isLoading:{
      type:Boolean,
      default:false,
    },
  },
  data() {
    return {
      selectedValue: "",
      show: false,
      active: false,
      selectedOption: "option1", // Initial selected option
      listData: [],
      chartData: [],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        legend: {
          show: false,
        },
        labels: [],
        colors: [],
        responsive: [{
          stroke: {
            width: 4,
          },
        }],
      },
      categories:[]
    };
  },
  mounted(){
    this.categories = [this.data[0]]
    this.updateChartData()
  },
  computed: {
    // Define your data for different options here
  
  },
  methods: {
    dataForOption1(arr) {
      const data = arr.map(item => { return item.name })
      return data
    },
    dataForOption2(arr) {
      this.chartOptions.labels = []
      const data = arr.map((item,index) => {
        const colorArray = ["#FF5733","#33FF57","#5733FF","#FF3357","#33FFA2","#A233FF","#FFA233","#FF5733","#33FF57","#5733FF","#FF3357","#33FFA2","#A233FF","#FFA233","#FF5733","#33FF57","#5733FF","#FF3357","#33FFA2","#A233FF","#FFA233","#FF5733","#33FF57","#5733FF","#FF3357","#33FFA2","#A233FF","#FFA233","#FF5733","#33FF57"]

        let bgColor = colorArray[index]
        let obj = {
          icon: "CircleIcon",
          iconColor: bgColor,
          text: item.name,
          result: item.coupon_count
        }
        return obj
      })
      return data
    },
    dataforOption3(arr) {
      const data = arr.map(item => {
        return parseInt(item.coupon_count ? item.coupon_count : 0)
      })
      return data
    },
    reset(){
     this.categories = [this.data[0]]
     this.updateChartData()
    },
    handleClickVs(){
      // document.body.addEventListener('scroll',this.handleScroll)
    },
    handleScroll(){
      let div = document.getElementsByClassName('activeOptions')
      if(div.length>0){
        document.getElementById('coupanTitle').click()
        this.$refs.mySelect.isActive = false; // Close the vs-select dropdown
        this.$forceUpdate()
      }
    },
    async updateChartData() {
      
      this.show = false
      let all = this.categories.findIndex(item=>item.id == 'all')
      let categoryData = this.categories.slice()
      if(all != -1 ){
        categoryData = this.data.slice(1)
        this.categories = [this.data[0]] 
      }
      this.chartData = await this.dataforOption3(categoryData);
      this.listData = await this.dataForOption2(categoryData);
      this.chartOptions.labels = await this.dataForOption1(categoryData);
      this.chartOptions.colors = await this.listData.map(item => {return item.iconColor})
      // this.chartOptions.labels = ["Category A", "Category B", "Category C"]
      this.show = true
      this.$forceUpdate()
      // } else if (this.selectedOption === "option2") {
      //   this.chartData = this.dataForOption2;
      // }
      // Add more conditions for other options if needed
    },
  },
  created() {
    // Initialize the chart data based on the initial selected option
    // this.updateChartData();
  },
};
</script>

<style scoped>

.bottom-0 {
  bottom: 0;
}

.card-uparrow {
  font-family: "Material Design Icons";
}

.catgory-labels::-webkit-scrollbar{
  width: 8px;
}
.catgory-labels::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px #EBF0FA; 
border-radius: 10px;
}

.catgory-labels::-webkit-scrollbar-thumb {
background: #D3DBEB; 
border-radius: 10px;
}
.coupon-card-container {
  height: 325px !important;
}

.catgory-labels::-webkit-scrollbar-thumb:hover {
background: #D3DBEB; 
}
.pie-graph-reset-btn {
  color: #7d8592;
  border-radius: 8px;
}

.pie-graph-reset-btn:focus {
  background: #f4f9fd;
}

.pie-graph-reset-btn::before {
  border: 1px solid #d8e0f0;
}

.cate-vs-select .vs-select__input.simple {
  border: 1px solid #d8e0f0;
  border-radius: 15px;
}

.couponCustom ::-webkit-scrollbar{
  display: none;
}

</style>
<style>
.couponCustom .vs__dropdown-toggle{
  max-height: 36px !important;
  overflow: auto !important;
}
.vs__selected-options input{
  margin-left:10px !important;
} 
</style>