<template>
  <div>
  <div class="mb-2 font-weight-bolder dash-coupon-list-title h1 ml-1">
    Coupons Added Over Time Statistics{{ set }}</div>
    
    <b-card v-if="isLoading" style="height: 150px;"> 
        <div class="loading">
          <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
      </b-card>
  <b-card
    no-body
    class="card-revenue-budget dash-total-card mb-2" v-else-if="lineChartSimple.series.length > 0"
  >
      <vue-apex-charts
        type="line"
        height="400"
        :options="lineChartSimple.chartOptions"
        :series="lineChartSimple.series"
      />
  </b-card>
</div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { computed } from '@vue/composition-api';

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    data: {
      type: Array,
      default: () => { },
    },
    isLoading:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      show:false,
      lineChartSimple: {
    series: [
      {
          name: "Coupons",
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        // strokeColors: [$themeColors.light],
        // colors: [$themeColors.warning],
      },
      // colors: [$themeColors.warning],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        // custom(data) {
        //   return `${'<div class="px-1 py-50"><span>'}${
        //     data.series[data.seriesIndex][data.dataPointIndex]
        //   }%</span></div>`
        // },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },
    };
  },
  async mounted(){
    if(this.isLoading){
     await this.setData()
    }
    this.show = true
  },
  async created(){
    if(this.isLoading){
     await this.setData()
    }
  },
    computed:{
       set(){
      if(!this.isLoading){
       this.setData()
       }
      }
    },
  methods:{
    setData(){
      this.lineChartSimple.series[0].data = this.data.map( i => { return parseInt(i.coupon_count)})
    this.lineChartSimple.chartOptions.xaxis.categories = this.data.map(i => { return i.month})
    console.log(this.lineChartSimple,"lineChartSimple-=-=-")
    this.$forceUpdate()
    }
  }
};
</script>
