<template>
  <section id="dashboard-ecommerce" class="container" style="background-color: transparent;">
    <div class="d-lg-flex justify-content-between">
    <h1 class="font-weight-bolder text-black" style="font-size: 36px;">Dashboard{{ app }}</h1>
    <div class="d-flex  flex-end">
    <div class="center custom-vs-select" style="border: none;width:4cm">
      <!-- <label style="font-size:15px">Month</label> -->
      <v-select
    v-model="month"
    class="custom-dropdown"
    shadow
    placeholder="Select"
    :clearable="false"
    label="text"
    :options="monthOptions"
  ></v-select>

            </div>
    <div class="center custom-vs-select ml-1" style="border: none;width:3cm">
      <!-- <label style="font-size:15px">Year</label> -->
    <v-select  v-model="year" shadow placeholder="Select"
    class="custom-dropdown" :clearable="false"    :options="yearOptions">
            </v-select>
            </div>
            </div>
  </div>
    <b-row class="match-height mt-1">
      <b-col v-if="isLoading.dashboardWidgets" style="height: 150px;" xl="12" md="12"> 
        <div class="loading">
          <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
      </b-col>
      <template v-else>
      <b-col v-if="item.app.includes($store.state.application.id)" v-for="(item,index) in dashboardWidgets" :key="index" xl="3" md="6">
        <b-card 
        @click="$router.push(item.redirect)"
    v-if="data"
    class="card-tiny-line-stats dash-total-card"
    body-class="p-250 p-2"
  >
    <!-- <h6>Total No. Of Coupons</h6> -->
    <div class="card-title"  style="font-size:  13px;color:#7D8592;font-weight: 600;">{{item.text}}</div>
    <b-row>
      <b-col class="dash-card-score">
        <div class="font-weight-bolder mb-0 card-value" style="font-size: 44px;">
          {{item.value}}
        </div>
        <div class="card-bottom" v-if="item.growthValue != 0 && item.growthValueShow" style="font-size: 13px;" :style="`color: ${item.growthValue < 0 ?  'red'  :  '#0AC947;'}`">{{item.growthValue < 0 ? 'Decreased' : 'Increased'}} {{item.growthValue < 0 ?  ''  :  '+'}}{{ item.growthValue }}</div>
      </b-col>
      <b-col class="dash-card-chart">
        <!-- <vue-apex-charts
          height="70"
          :options="statisticsProfit.chartOptions"
          :series="data.series"
        /> -->
      </b-col>
      
    </b-row>
  </b-card>
      </b-col>
    </template>
      <!-- <b-col
        xl="6"
        md="6"
      >
        <dashboard-line-chart :data="data.revenue" />
      </b-col> -->
      <b-col class="mt-1" xl="6" lg="6" md="12" sm="12">
        <most-coupied-coupons :data="copiedCoupons" :isLoading="isLoading.copiedCoupons" />
      </b-col>
      <b-col class="mt-1" xl="6" lg="6" md="12" sm="12">
        <frequently-used-coupons :data="usedCoupons" :isLoading="isLoading.copiedCoupons"/>
      </b-col>
      <b-col class="mt-3" xl="3" lg="6" md="12" sm="12">
        <trending-search-queries :data="trendingSearchQueries" :isLoading="isLoading.trendingSearchQueries"/>
      </b-col>
      <b-col class="mt-3" xl="9" lg="6" md="12" sm="12">
        <coupons-per-Category v-if="!isLoading.categoryChart"  :data="categoryChartData" :isLoading="isLoading.categoryChart"/>
      </b-col>
      <b-col class="mt-1" xl="12"  lg="6" md="12" sm="12">
        <coupons-added-over-time-statistics  :data="overTimeChartData" :isLoading="isLoading.overTimeChart"/>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import vSelect from 'vue-select';
import { getUserData } from "@/auth/utils";
import DashboardLineChart from "./DashboardLineChart.vue";
import DashboardTotalCoupon from "./DashboardTotalCoupon.vue";
import DashboardTotalNewCoupon from "./DashboardTotalNewCoupon.vue";
import DashboardTotalUpdateCoupon from "./DashboardTotalUpdateCoupon.vue";
import DashboardTotalClient from "./DashboardTotalClient.vue";
import DashboardTotalNewClient from "./DashboardTotalNewClient.vue";
import DashboardTotalDeal from "./DashboardTotalDeal.vue";
import MostCoupiedCoupons from "./MostCoupiedCoupons.vue";
import FrequentlyUsedCoupons from "./FrequentlyUsedCoupons.vue";
import TrendingSearchQueries from "./TrendingSearchQueries.vue";
import CouponsPerCategory from "./CouponsPerCategorey.vue";
import CouponsAddedOverTimeStatistics from "./CouponsAddedOverTimeStatistics.vue";
import dashboard from '@/router/routes/dashboard';

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    DashboardLineChart,
    DashboardTotalCoupon,
    DashboardTotalNewCoupon,
    DashboardTotalUpdateCoupon,
    DashboardTotalClient,
    DashboardTotalNewClient,
    DashboardTotalDeal,
    MostCoupiedCoupons,
    FrequentlyUsedCoupons,
    TrendingSearchQueries,
    CouponsPerCategory,
    CouponsAddedOverTimeStatistics
  },
  data() {
    return {
      data: {},
      baseURL: process.env.VUE_APP_BASE_URL,
      dashboardWidgets:[],
      isLoading:{
        dashboardWidgets: true,
        copiedCoupons: true,
        trendingSearchQueries: true,
        categoryChart: true,
        overTimeChart: true,
      },
      copiedCoupons:[],
      trendingSearchQueries:[],
      usedCoupons:[],
      categoryChartData:[],
      overTimeChartData:[],
      yearOptions: [],
      monthOptions: [
        { text: "January", value: "01" },
        { text: "February", value: "02" },
        { text: "March", value: "03" },
        { text: "April", value: "04" },
        { text: "May", value: "05" },
        { text: "June", value: "06" },
        { text: "July", value: "07" },
        { text: "August", value: "08" },
        { text: "September", value: "09" },
        { text: "October", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" }
      ],
      month: { text: "January", value: "01" },
      categories:[],
      year:''
    };
  },
  computed:{
    app(){
      if(this.$store.state.application.id){
        this.categories = []
        this.getData(this.$store.state.application.id)
      }
    }
  },
  created() {
    // data
    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;
        
      // ? Your API will return name of logged in user or you might just directly get name of logged in user
      // ? This is just for demo purpose
      const userData = getUserData();
      this.data.congratulations.name =
        userData.fullName.split(" ")[0] || userData.username;
    });
  },
  mounted(){
    // if(this.$store.state.application.id){
    //   this.getData(this.$store.state.application.id)
    // }
  },
  methods:{
     async getData(id){
      
      this.isLoading ={
        dashboardWidgets: true,
        copiedCoupons: true,
        trendingSearchQueries: true,
        categoryChart: true,
        overTimeChart: true,
      }
      if(!this.year){
        this.month = this.monthOptions[new Date().getMonth()]
        this.year = new Date().getFullYear()
        for(let i = this.year-9; i<=this.year+1;i++){
        this.yearOptions.push(i)
    }
      }
     await this.getDataAll(id)
  },
  async getDataAll(id){
        this.$http.post(`${this.baseURL}/dashboard-widget/index`, { app_id: id ? id : this.$store.state.application.id, month: this.month.value, year: this.year }).then((json) => {
      this.dashboardWidgets = [
       {
                             value:json.data.totalCoupons,
                             text:'Total No. Of Coupons',
                             growthValue:json.data.growthTotalCoupons,
                             growthValueShow:true,
                             redirect:'/offers',
                             app:[1,2,3,4,5,6]
                           },
                           {
                             value:parseInt(json.data.totalLinkedBasedCoupons),
                             text:'Total No. Of Link Based Coupons',
                             growthValue: parseInt(json.data.growthTotalLinkedBasedCoupons),
                             growthValueShow:true,
                             redirect:'/offers',
                             app:[1,2,3,4,5,6]
                           },
                           {
                             value:json.data.totalAllCoupons,
                             text:'Total No. Of All Coupons',
                             growthValue:json.data.growthTotalAllCoupons,
                             growthValueShow:true,
                             redirect:'/offers',
                             app:[1,2,3,4,5,6]
                           },
                           {
                             value:json.data.totalNewCoupons,
                             text:'Total No. Of New Coupons',
                             growthValue:json.data.growthTotalNewCoupons,
                             growthValueShow:true,
                             redirect:'/offers',
                             app:[1,2,3,4,5,6]
                           },
                           {
                             value:json.data.totalNewClients,
                             text:'Total No. Of New Clients',
                             growthValue:json.data.growthTotalNewClients,
                             growthValueShow:true,
                             redirect:'/clients-and-stores',
                             app:[1,2,3,4,5,6]
                           },
                           {
                             value:json.data.totalDeals,
                             text:'Total No. Of Deals',
                             growthValue:json.data.growthTotalDeals,
                             growthValueShow:true,
                             redirect:'/deals',
                             app:[1,2]
                           },
                           {
                             value:json.data.totalUpdatedCoupons,
                             text:'Total No. Of Updated Coupons',
                             growthValue:json.data.growthTotalUpdatedCoupons,
                             growthValueShow:true,
                             redirect:'/offers',
                             app:[1,2,3,4,5,6]
                           },
                           {
                             value:json.data.totalClients,
                             text:'Total No. Of Clients',
                             growthValue:json.data.growthTotalClients,
                             growthValueShow:true,
                             redirect:'/clients-and-stores',
                             app:[1,2,3,4,5,6]
                           },
       ];
      this.isLoading.dashboardWidgets = false  
      })
        this.$http.post(`${this.baseURL}/dashboard-widget/copied-coupons`, { app_id: id ? id : this.$store.state.application.id }).then((json) => { 
          this.copiedCoupons = json.data.copiedCoupons;
          this.usedCoupons = json.data.usedCoupons;
          this.isLoading.copiedCoupons = false  
        })
        this.$http.post(`${this.baseURL}/dashboard-widget/trending-search-queries`).then((json) => { 
          this.trendingSearchQueries = json.data.data;
          this.isLoading.trendingSearchQueries = false  
        })
        this.$http.post(`${this.baseURL}/dashboard-widget/category-chart`).then((json) => { 
           this.categoryChartData = json.data.data; 
           this.categoryChartData.unshift({id:'all',name:'All'});
          this.isLoading.categoryChart = false  
        })
        this.$http.post(`${this.baseURL}/dashboard-widget/over-time-chart`, { month: this.month.value, year: this.year }).then((json) => {
          this.overTimeChartData = json.data.data;
          this.isLoading.overTimeChart = false  
        })
  }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

p.thicker {
  font-weight: 900;
}
</style>
<style >
.custom-dropdown .vs__dropdown-menu {
  max-height: 200px; /* Adjust the value according to your needs */
  overflow-y: auto;  /* Enable vertical scrolling if needed */
}
</style>