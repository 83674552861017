<template>
  <div>
    <div class="mb-2 font-weight-bolder dash-coupon-list-title h2 ml-1">
      Recently Added Coupons<span class="float-right text-blue mt-1" style="font-size: 15px;cursor: pointer;" @click="$router.push('/offers')"
        >View all <v-icon class="text-blue">mdi-chevron-right</v-icon></span
      >
    </div>
    <div class="coupons-card-container" style="padding:0px 9px">
      
      <b-card v-if="isLoading" style="height: 150px;"> 
        <div class="loading">
          <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
      </b-card>
      <template v-else>
      <b-card
        v-for="item in data"
        :key="item"
        no-body
        class="card-revenue-budget dash-total-card mb-2"
      >
        <b-row class="mx-0 card-body" style="padding: 2rem 1rem !important">
          <b-col md="6">
            <div class="d-flex align-items-center">
              <div class="mr-2">
                  <b-img v-bind="mainProps" rounded="circle" src="../../assets/images/custom/noon.svg"
                    alt="Circle image" class="d-inline-block" />
              </div>
              <div>
                <div class="font-weight-bolder text-black h5">{{item.app_name}}</div>
                <a :href="item.redirect_url" target="_blank">{{ item.redirect_url ? item.redirect_url.slice(0, 20) : '' }}{{ item.redirect_url ? item.redirect_url.length
                    > 20 ? '...' : '' : '' }}</a>
              </div>
            </div>
          </b-col>
          <b-col md="6" class="position-relative">
            <div>
              <div class="">
                Category
                <span class="float-right d-flex align-items-start" style="color: #0ac947"
                  >
                  <img src="../../assets/images/svg/arrowDown.svg" class=""/>&nbsp;&nbsp;
                  <!-- 1 -->
                  </span
                >
              </div>
              <div class="font-weight-bolder text-black " style="width:95%">{{item.categories.map(item => {return item.name}).slice(0,3).join(',')}}{{ item.categories.length > 3 ? '...' : '' }}</div>
            </div>
          </b-col>
          <b-col md="6" class="py-0">
            <div class="mt-1" style="font-weight: 600">Copied {{item.click_count ? item.click_count : 0}} times</div>
            </b-col>
          <b-col md="6" class="py-0">
            <div class="mt-1">
              Coupon Code
              <span class="font-weight-bolder" style="color: #3f8cff"
                >{{item.code}}</span
              >
            </div>
            </b-col>
        </b-row>
      </b-card>
      </template>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
    isLoading:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      revenue_report: {},
      mainProps: {
        width: 50,
        height: 50,
        class: 'm1',
      },
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: "line",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
            xaxis: {
              lines: { show: true },
            },
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
            ],
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          // plotOptions: {
          //   bar: {
          //     columnWidth: '17%',
          //     endingShape: 'rounded',
          //   },
          //   distributed: true,
          // },
          yaxis: {
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
          },
        },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            sparkline: { enabled: true },
          },
          stroke: {
            curve: "smooth",
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, "#dcdae3"],
          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.bottom-0 {
  bottom: 0;
}
.card-uparrow {
  font-family: "Material Design Icons";
}
</style>