<template>
  <b-card
    v-if="data"
    class="card-tiny-line-stats dash-total-card"
    body-class="p-250"
  >
    <div class="card-title">Total No. Of Deals</div>
    <b-row>
      <b-col class="dash-card-score">
        <h1 class="font-weight-bolder mb-0 card-value">
          10
        </h1>
        <div class="card-bottom" style="color: #0AC947;">Increased +3</div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
}
</script>
