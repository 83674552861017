<template>
  <div>
    <div class="mb-2 font-weight-bolder h2 dash-coupon-list-title">
      Trending Search Queries
    </div>
    <div class="coupons-card-container" style="font-size: 17px">
      <b-card v-if="isLoading" style="height: 150px;"> 
        <div class="loading">
          <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
      </b-card>
      <template v-else>
      <b-card
        v-for="item in data"
        :key="item.id"
        no-body
        class="card-revenue-budget dash-total-card mb-2" style="padding: 17px 20px;"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="text-black font-weight-bolder" @click="txtShowClick(item.id)" :style="textshow[item.id] ? '' : 'text-overflow: ellipsis;white-space:nowrap;overflow: hidden;'">{{item.name}}</div>
          <div class="float-right mr-2 font-weight-bolder">{{item.count}}</div>
        </div>
      </b-card>
      </template>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
    isLoading:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      revenue_report: {},
      textshow:{},
    };
  },
  methods:{
    txtShowClick(id){
      this.textshow[id] = this.textshow[id] == undefined ? false : this.textshow[id]
      this.textshow[id] = this.textshow[id] == false ? true : false
      this.$forceUpdate()
    }
  }
};
</script>
<style scoped>
.bottom-0 {
  bottom: 0;
}
.card-uparrow {
  font-family: "Material Design Icons";
}

@media only screen and (max-width: 1500px) {
 
 .coupons-card-container {
  max-height: 290px !important;
 }
 }
 @media only screen and (min-width: 1500px) {
   .coupons-card-container {
  max-height: 317px !important;
 }
 }
.coupons-card-container .card{
  width:95%;
}
</style>